@import '../../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  background-color: var(--colorWhite);
  padding-bottom: 100px;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > h1 {
    font-size: 20px;
    font-weight: var(--fontWeightSemiBold);
    line-height: 130%;
    color: var(--textColor);
    padding: 0;
    margin: 20px 0 10px 0;
  }
  & > ul {
    & > li {
      font-size: 18px;
      line-height: 130%;
      font-weight: var(--fontWeightRegular);
      color: var(--textLightColor);
      display: flex;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      & > svg {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        & path {
          fill: var(--colorSuccess);
        }
      }
    }
  }
  & .authBtns {
    display: flex;
    align-items: center;
    margin-top: 24px;
    flex-direction: column;
    width: 100%;
    @media (--viewportSmall) {
      flex-direction: row;
    }
    & > * {
      width: 100%;
      display: inline-block;
      @media (--viewportSmall) {
        width: auto;
      }
      &:not(:last-child) {
        margin-bottom: 16px;
        @media (--viewportSmall) {
          margin-right: 24px;
          margin-bottom: 0px;
        }
      }
    }
  }
}

.accountLinksWrapper,
.customLinksWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.accountLinksWrapper {
  margin-bottom: 36px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 14px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: 48px 0 0 0;

  & > span {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: var(--fontWeightBold);
  }
}

.greeting {
  /* Font */
  composes: h4 from global;
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--colorGrey300);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.inbox {
  /* Font */
  composes: p from global;
  color: var(--colorGrey800);
  position: relative;
  font-size: 16px;

  margin-top: auto;
  margin-bottom: 13px;
  width: 100%;
  &:hover {
    text-decoration: none;
    color: var(--marketplaceColor);
  }
}

.navigationLink {
  /* Font */
  composes: p from global;
  color: var(--colorGrey800);
  font-size: 16px;
  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
  width: 100%;
  &:hover {
    text-decoration: none;
    color: var(--marketplaceColor);
  }
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: buttonPrimary from global;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;

  margin-bottom: 48px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  composes: blueBtn from global;
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  composes: buttonBorder from global;
  border-color: var(--marketplaceColor);
  color: var(--marketplaceColor);
  &:hover {
    border-color: var(--marketplaceColor);
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }
}

.currentPage {
  color: var(--colorBlack);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.spacer {
  width: 100%;
  height: 124px;
}
